import './App.scss';
import Login from '../components/login';
import Register from '../components/register';

function User() {
  return (
    <div className="App">
        <p> Bouquet </p>
        <p> This is user </p>
        <Login />

    </div>
  );
}

export default User;