import Success from "../components/Success";
import "./App.scss";

function Publish() {
  return (
    <div className="App">
      <Success />
    </div>
  );
}

export default Publish;
